<template>

	<div style="background: #fff;padding: 15px;overflow:hidden" class="ml-goodList">
		<el-button style='margin-bottom:10px;' @click='handleExport' v-loading='exportloading'>导出</el-button>
		<!-- 表格 -->
		<div class="table-container">
			<el-table :data="tableList" style="width: 100%;" v-loading="loading"  @sort-change="sortChange">
				<el-table-column label="商品" >
					<template slot-scope="scope">
						<div class="product-message">
							<img style="width:65px;height:65px;margin-right:10px;" :src="imgUrl+scope.row.ImgUrl">
							<div >
                <div style="width:300px;overflow: hidden;display:-webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical; ">
                  <span  style="color: #f00;font-size: 12px;" v-if="scope.row.ProductType==1">[组合]</span>
                  {{scope.row.Name}}
                </div>
                <div style="color: #999;font-size: 12px;" v-if='!scope.row.IsSupplierProduct'>{{scope.row.ProductNo}}</div>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="MemberCount" label="到货提醒人数" sortable></el-table-column>
				<el-table-column prop="Stock" label="库存" sortable></el-table-column>
			</el-table>
      <el-pagination v-if="page.total"  style="margin-top:20px;float:right;" @size-change="handleSizeChange"
			 @current-change="handleCurrentChange" :current-page="page.current" :page-sizes="[10, 20, 30, 40, 50]" :page-size="page.size"
			 layout="total, sizes, prev, pager, next, jumper" :total="page.total">
			</el-pagination>
		</div>

	</div>

</template>

<script>
  import apilist from '@/api/other'
	import config from '@/config/index'
	export default {
		data() {
      return {
				exportloading: false,
        loading: false,
        imgUrl: config.IMG_BASE,
        exportUrl: config.EXPORT_URL,
        tableList: [],
        page:{
          total:0,
          size:20,
          current:1
				},
				IsAsc:'',
				OrderBy:'',
      }
		},

		beforeMount() {
      this.getData()
		},
		methods: {
      async getData(){
				this.loading = true
				let result  = await apilist.productSubscribeList(
					{
						Skip: (this.page.current - 1) * this.page.size, 
						Take: this.page.size,
						IsAsc:this.IsAsc,
						OrderBy:this.OrderBy,
					}
				)
				this.tableList = result.Result.Results
				this.page.total = result.Result.Total
				
				this.loading = false
			},
				
			// 导出
			async handleExport() {
				this.exportloading = true;
				try {
					let url = this.exportUrl+'/pc/productSubscribe/export?'+
					'&IsAsc='+this.IsAsc+
					'&OrderBy='+this.OrderBy
					window.open(url);
				} catch (error) {
					console.log(error);
				} finally {
					this.exportloading = false;
				}
			},
			sortChange(column, prop, order) {
				if (column.order == "ascending") {
					this.IsAsc = true
					this.OrderBy = column.prop
				} else if(column.order == "descending") {
					this.IsAsc = false
					this.OrderBy = column.prop
				} else {
					this.IsAsc = ''
					this.OrderBy = ''
				}
        this.page.current = 1;
        this.getData()
			},
      handleSizeChange(val){
        this.page.size = val
        this.getData()
      },
      
      handleCurrentChange(val){
        this.page.current = val
        this.getData()
      }
		}
	};
</script>

<style lang="less" scoped>
.product-message{
  display:flex;
}
</style>
